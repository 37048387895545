/*!
 * Start Bootstrap - Agency Bootstrap Theme (http://startbootstrap.com)
 * Code licensed under the Apache License v2.0.
 * For details, see http://www.apache.org/licenses/LICENSE-2.0.
 */
body {
    overflow-x: hidden;
    font-family: $font-primary;
    color: $color-primary;
}
.text-muted {
    color: $color-muted;
}
.text-primary {
    color: $color-primary;
}
p {
    font-size: 1.1em;
    line-height: 1.5em;
    font-weight: 500;
}
p.large {
    font-size: 1.2em;
}
a,
a:hover,
a:focus,
a:active,
a.active {
    outline: 0;
}
a {
    color: $color-secondary;
}
a:hover,
a:focus,
a:active,
a.active {
    color: $color-secondary;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    text-transform: uppercase;
    font-family: $font-primary;
    font-weight: 700;
}
.img-centered {
    margin: 0 auto;
}
.bg-light-gray {
    background-color: #f7f7f7;
}
.bg-darkest-gray {
    background-color: #222;
}
.btn-primary {
    border-color: $color-primary;
    text-transform: uppercase;
    font-family: $font-primary;
    font-weight: 700;
    color: #fff;
    background-color: $color-primary;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
    border-color: $color-primary;
    color: #fff;
    background-color: $color-secondary;
}
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
    background-image: none;
}
.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
    border-color: $color-primary;
    background-color: $color-primary;
}
.btn-primary .badge {
    color: $color-primary;
    background-color: #fff;
}
.btn-xl {
    padding: 20px 40px;
    border-color: $color-primary;
    border-radius: 3px;
    text-transform: uppercase;
    font-family: $font-primary;
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    background-color: $color-primary;
}
.btn-xl:hover,
.btn-xl:focus,
.btn-xl:active,
.btn-xl.active,
.open .dropdown-toggle.btn-xl {
    border-color: $color-primary;
    color: #fff;
    background-color: $color-secondary;
}
.btn-xl:active,
.btn-xl.active,
.open .dropdown-toggle.btn-xl {
    background-image: none;
}
.btn-xl.disabled,
.btn-xl[disabled],
fieldset[disabled] .btn-xl,
.btn-xl.disabled:hover,
.btn-xl[disabled]:hover,
fieldset[disabled] .btn-xl:hover,
.btn-xl.disabled:focus,
.btn-xl[disabled]:focus,
fieldset[disabled] .btn-xl:focus,
.btn-xl.disabled:active,
.btn-xl[disabled]:active,
fieldset[disabled] .btn-xl:active,
.btn-xl.disabled.active,
.btn-xl[disabled].active,
fieldset[disabled] .btn-xl.active {
    border-color: $color-primary;
    background-color: $color-primary;
}
.btn-xl .badge {
    color: $color-primary;
    background-color: #fff;
}
.navbar-default {
    border-color: transparent;
    background-color: #222;
}
.navbar-default .navbar-brand {
    font-family: "Kaushan Script","Helvetica Neue",Helvetica,Arial,cursive;
    color: $color-primary;
}
.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus,
.navbar-default .navbar-brand:active,
.navbar-default .navbar-brand.active {
    color: $color-secondary;
}
.navbar-default .navbar-collapse {
    border-color: rgba(255,255,255,.02);
}
.navbar-default .navbar-toggle {
    border-color: $color-primary;
    background-color: $color-primary;
}
.navbar-default .navbar-toggle .icon-bar {
    background-color: #fff;
}
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
    background-color: $color-primary;
}
.navbar-default .nav li a {
    text-transform: uppercase;
    font-family: $font-primary;
    font-weight: 400;
    letter-spacing: 1px;
    color: #fff;
    -webkit-transition: all ease .35s;
    -moz-transition: all ease .35s;
    transition: all ease .35s;
}
.navbar-default .nav li a:hover,
.navbar-default .nav li a:focus {
    outline: 0;
    color: #fdc124;
}
.navbar-default .navbar-nav>.active>a {
    border-radius: 0;
    color: #fff;
    background-color: $color-primary;
}
.navbar-default .navbar-nav>.active>a:hover,
.navbar-default .navbar-nav>.active>a:focus {
    color: #fff;
    background-color: $color-secondary;
}
@media(min-width:768px) {
    .navbar-default {
        padding: 25px 0;
        border: 0;
        background-color: transparent;
        -webkit-transition: padding .3s;
        -moz-transition: padding .3s;
        transition: padding .3s;
    }
    .navbar-default .navbar-brand {
        font-size: 2em;
        -webkit-transition: all .3s;
        -moz-transition: all .3s;
        transition: all .3s;
    }
    .navbar-default .navbar-nav>.active>a {
        border-radius: 3px;
    }
    .navbar-default.navbar-shrink {
        padding: 10px 0;
        background-color: #222;
    }
    .navbar-default.navbar-shrink .navbar-brand {
        font-size: 1.5em;
    }
}
header {
    text-align: center;
    color: #fff;
    background-attachment: scroll;
    background-image: url("#{$site-baseurl}img/header-bg.jpg");
    background-position: center center;
    background-repeat: none;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
}
header .intro-text {
    padding-top: 100px;
    padding-bottom: 50px;
}
header .intro-text .intro-lead-in {
    margin-bottom: 25px;
    font-family: $font-secondary;
    font-size: 22px;
    font-style: italic;
    line-height: 22px;
}
header .intro-text .intro-heading {
    margin-bottom: 25px;
    text-transform: uppercase;
    font-family: $font-primary;
    font-size: 50px;
    font-weight: 700;
    line-height: 50px;
}
@media(min-width:768px) {
    header .intro-text {
        padding-top: 300px;
        padding-bottom: 200px;
    }
    header .intro-text .intro-lead-in {
        margin-bottom: 25px;
        font-family: $font-secondary;
        font-size: 40px;
        font-style: italic;
        line-height: 40px;
    }
    header .intro-text .intro-heading {
        margin-bottom: 50px;
        text-transform: uppercase;
        font-family: $font-primary;
        font-size: 75px;
        font-weight: 700;
        line-height: 75px;
    }
}
section {
    padding: 100px 0;
}
section h2.section-heading {
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 40px;
}
section h3.section-subheading {
    margin-bottom: 75px;
    text-transform: none;
    font-family: $font-secondary;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
}
@media(min-width:768px) {
    section {
        padding: 150px 0;
    }
}
.service-heading {
    font-size: 1.2em;
    margin: 15px 0;
    text-transform: none;
}
#our-approach .our-approach-item {
    right: 0;
    margin: 0 0 15px;
}
#our-approach .our-approach-item .our-approach-link {
    display: block;
    position: relative;
    margin: 0 auto;
    max-width: 400px;
}
#our-approach .our-approach-item .our-approach-link .our-approach-hover {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    -webkit-transition: all ease .5s;
    -moz-transition: all ease .5s;
    transition: all ease .5s;
    background: rgba(nth($color_primary_rgb, 1), nth($color_primary_rgb, 2), nth($color_primary_rgb, 3), .9);
}
#our-approach .our-approach-item .our-approach-link .our-approach-hover:hover {
    opacity: 1;
}
#our-approach .our-approach-item .our-approach-link .our-approach-hover .our-approach-hover-content {
    position: absolute;
    top: 50%;
    width: 100%;
    height: 20px;
    margin-top: -12px;
    text-align: center;
    font-size: 20px;
    color: #fff;
}
#our-approach .our-approach-item .our-approach-link .our-approach-hover .our-approach-hover-content i {
    margin-top: -12px;
}
#our-approach .our-approach-item .our-approach-link .our-approach-hover .our-approach-hover-content h3,
#our-approach .our-approach-item .our-approach-link .our-approach-hover .our-approach-hover-content h4 {
    margin: 0;
}
#our-approach .our-approach-item .our-approach-caption {
    margin: 0 auto;
    padding: 25px;
    max-width: 400px;
    text-align: center;
    background-color: #fff;
}
#our-approach .our-approach-item .our-approach-caption h4 {
    margin: 0;
    text-transform: none;
}
#our-approach .our-approach-item .our-approach-caption p {
    margin: 0;
    font-family: $font-secondary;
    font-size: 16px;
    font-style: italic;
}
#our-approach * {
    z-index: 2;
}
@media(min-width:767px) {
    #our-approach .our-approach-item {
        margin: 0 0 30px;
    }
}
.timeline {
    position: relative;
    padding: 0;
    list-style: none;
}
.timeline:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 40px;
    width: 2px;
    margin-left: -1.5px;
    background-color: #f1f1f1;
}
.timeline>li {
    position: relative;
    margin-bottom: 50px;
    min-height: 50px;
}
.timeline>li:before,
.timeline>li:after {
    content: " ";
    display: table;
}
.timeline>li:after {
    clear: both;
}
.timeline>li .timeline-panel {
    float: right;
    position: relative;
    width: 100%;
    padding: 0 20px 0 100px;
    text-align: left;
}
.timeline>li .timeline-panel:before {
    right: auto;
    left: -15px;
    border-right-width: 15px;
    border-left-width: 0;
}
.timeline>li .timeline-panel:after {
    right: auto;
    left: -14px;
    border-right-width: 14px;
    border-left-width: 0;
}
.timeline>li .timeline-image {
    z-index: 100;
    position: absolute;
    left: 0;
    width: 80px;
    height: 80px;
    margin-left: 0;
    border: 7px solid #f1f1f1;
    border-radius: 100%;
    text-align: center;
    color: #fff;
    background-color: $color-primary;
}
.timeline>li .timeline-image h4 {
    margin-top: 12px;
    font-size: 10px;
    line-height: 14px;
}
.timeline>li.timeline-inverted>.timeline-panel {
    float: right;
    padding: 0 20px 0 100px;
    text-align: left;
}
.timeline>li.timeline-inverted>.timeline-panel:before {
    right: auto;
    left: -15px;
    border-right-width: 15px;
    border-left-width: 0;
}
.timeline>li.timeline-inverted>.timeline-panel:after {
    right: auto;
    left: -14px;
    border-right-width: 14px;
    border-left-width: 0;
}
.timeline>li:last-child {
    margin-bottom: 0;
}
.timeline .timeline-heading h4 {
    margin-top: 0;
    color: inherit;
}
.timeline .timeline-heading h4.subheading {
    text-transform: none;
}
.timeline .timeline-body>p,
.timeline .timeline-body>ul {
    margin-bottom: 0;
}
@media(min-width:768px) {
    .timeline:before {
        left: 50%;
    }
    .timeline>li {
        margin-bottom: 100px;
        min-height: 100px;
    }
    .timeline>li .timeline-panel {
        float: left;
        width: 41%;
        padding: 0 20px 20px 30px;
        text-align: right;
    }
    .timeline>li .timeline-image {
        left: 50%;
        width: 100px;
        height: 100px;
        margin-left: -50px;
    }
    .timeline>li .timeline-image h4 {
        margin-top: 16px;
        font-size: 13px;
        line-height: 18px;
    }
    .timeline>li.timeline-inverted>.timeline-panel {
        float: right;
        padding: 0 30px 20px 20px;
        text-align: left;
    }
}
@media(min-width:992px) {
    .timeline>li {
        min-height: 150px;
    }
    .timeline>li .timeline-panel {
        padding: 0 20px 20px;
    }
    .timeline>li .timeline-image {
        width: 150px;
        height: 150px;
        margin-left: -75px;
    }
    .timeline>li .timeline-image h4 {
        margin-top: 30px;
        font-size: 18px;
        line-height: 26px;
    }
    .timeline>li.timeline-inverted>.timeline-panel {
        padding: 0 20px 20px;
    }
}
@media(min-width:1200px) {
    .timeline>li {
        min-height: 170px;
    }
    .timeline>li .timeline-panel {
        padding: 0 20px 20px 100px;
    }
    .timeline>li .timeline-image {
        width: 170px;
        height: 170px;
        margin-left: -85px;
    }
    .timeline>li .timeline-image h4 {
        margin-top: 40px;
    }
    .timeline>li.timeline-inverted>.timeline-panel {
        padding: 0 100px 20px 20px;
    }
}
.team-member {
    margin-bottom: 50px;
    text-align: center;
}
.team-member img {
    margin: 0 auto;
    border: 7px solid #fff;
}
.team-member h4 {
    margin-top: 25px;
    margin-bottom: 0;
    text-transform: none;
}
.team-member p {
    margin-top: 0;
}
aside.clients img {
    margin: 50px auto;
}
section#contact {
    background-color: #222;
    background-image: url("#{$site-baseurl}img/map-image.png");
    background-position: center;
    background-repeat: no-repeat;
}
section#contact .section-heading {
    color: #fff;
}
section#contact .form-group {
    margin-bottom: 25px;
}
section#contact .form-group input,
section#contact .form-group textarea {
    padding: 20px;
}
section#contact .form-group input.form-control {
    height: auto;
}
section#contact .form-group textarea.form-control {
    height: 236px;
}
section#contact .form-control:focus {
    border-color: $color-primary;
    box-shadow: none;
}
section#contact::-webkit-input-placeholder {
    text-transform: uppercase;
    font-family: $font-primary;
    font-weight: 700;
    color: #bbb;
}
section#contact:-moz-placeholder {
    text-transform: uppercase;
    font-family: $font-primary;
    font-weight: 700;
    color: #bbb;
}
section#contact::-moz-placeholder {
    text-transform: uppercase;
    font-family: $font-primary;
    font-weight: 700;
    color: #bbb;
}
section#contact:-ms-input-placeholder {
    text-transform: uppercase;
    font-family: $font-primary;
    font-weight: 700;
    color: #bbb;
}
section#contact .text-danger {
    color: #e74c3c;
}
footer {
    padding: 25px 0;
    text-align: center;
}
footer span.copyright {
    text-transform: uppercase;
    text-transform: none;
    font-family: $font-primary;
    line-height: 40px;
}
footer ul.quicklinks {
    margin-bottom: 0;
    text-transform: uppercase;
    text-transform: none;
    font-family: $font-primary;
    line-height: 40px;
}
ul.social-buttons {
    margin-bottom: 0;
}
ul.social-buttons li a {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    font-size: 20px;
    line-height: 40px;
    outline: 0;
    color: #fff;
    background-color: #222;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    transition: all .3s;
}
ul.social-buttons li a:hover,
ul.social-buttons li a:focus,
ul.social-buttons li a:active {
    background-color: $color-primary;
}
.btn:focus,
.btn:active,
.btn.active,
.btn:active:focus {
    outline: 0;
}
.our-approach-modal .modal-content {
    padding: 100px 0;
    min-height: 100%;
    border: 0;
    border-radius: 0;
    text-align: center;
    background-clip: border-box;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.our-approach-modal .modal-content h2 {
    margin-bottom: 15px;
    font-size: 3em;
}
.our-approach-modal .modal-content p {
    margin-bottom: 30px;
}
.our-approach-modal .modal-content p.item-intro {
    margin: 20px 0 30px;
    font-family: $font-secondary;
    font-size: 16px;
    font-style: italic;
}
.our-approach-modal .modal-content ul.list-inline {
    margin-top: 0;
    margin-bottom: 30px;
}
.our-approach-modal .modal-content img {
    margin-bottom: 30px;
}
.our-approach-modal .close-modal {
    position: absolute;
    top: 25px;
    right: 25px;
    width: 75px;
    height: 75px;
    background-color: transparent;
    cursor: pointer;
}
.our-approach-modal .close-modal:hover {
    opacity: .3;
}
.our-approach-modal .close-modal .lr {
    z-index: 1051;
    width: 1px;
    height: 75px;
    margin-left: 35px;
    background-color: #222;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.our-approach-modal .close-modal .lr .rl {
    z-index: 1052;
    width: 1px;
    height: 75px;
    background-color: #222;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}
::-moz-selection {
    text-shadow: none;
    background: $color-primary;
    color: $color-primary-dark;
}
::selection {
    text-shadow: none;
    background: $color-primary;
    color: $color-primary-dark;
}
img::selection {
    background: 0 0;
}
img::-moz-selection {
    background: 0 0;
}
body {
    webkit-tap-highlight-color: $color-primary;
}
