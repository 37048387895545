$site-baseurl: "https://www.ixydo.com/";

// Color settings (hex-codes without the leading hash-tag)
$color-primary: #1d2a4a;
$color-secondary: #808080;
$color-muted: #808080;

// Create an SCSS array with the RGB colors of $color-primary for later use
$color_primary_rgb: ( 0,17,221,34,170,68,170 );

$color-primary-dark: #ffffff;
$color-secondary-dark: #fafafa;
$color-muted-dark: #ffffff;

// Font Families
$font-primary: "Nunito", sans-serif;
$font-secondary: "Nunito", sans-serif;

@import "bootstrap";
@import "agency";
@import "override";
