html {
    scroll-behavior: smooth;
}
h1, h2, h3, h4, h5, h6 {
    text-transform: none;
}
.navbar-default .navbar-nav>.active>a:hover,
.navbar-default .navbar-nav>.active>a:focus {
    background-color: $color-primary;
    color: #fdc124;
}
.navbar-brand img {
    margin-top: -17px;
    line-height: 0px;
    max-height: 2em;
    vertical-align: top;
}
.navbar-shrink img {
    transition: all 0.4s ease 0s;
    margin-top: -10px;
}
.navbar {
    background-color: #1d2a4a;
}
.navbar-default.navbar-shrink {
    background-color: #1d2a4a;
}
header {
    font-family: $font-primary;
    color: $color-primary;
    background-image: none;
    text-align: left;
}
header .intro-text {
    padding-top: 12em;
}
header .intro-text #main-illustration {
    width: 50%;
    float: right;
}
header .intro-text .intro-lead-in {
    font-style: normal;
    font-size: 38pt;
    line-height: 1.2em;
    text-align: left;
    width: 50%;
}
header .intro-text .intro-heading {
    text-transform: none;
    font-weight: normal;
    font-style: normal;
    font-size: 16pt;
    line-height: 1.2em;
    text-align: left;
    width: 50%;
}
.left {
    text-align: left;
}
.right {
    text-align: right;
}
section {
    padding: 150px 0;
}
.btn-xl {
    padding: 15px 30px;
    border-radius: 50px;
    text-transform: none;
    transition: color 0.4s ease 0s;
}
.btn-xl:hover {
    /*animation: btn-xl-animate-color 1s;*/
    transition: color 0.4s ease 0s;
}
.btn-xl:hover,
.btn-xl:focus,
.btn-xl:active,
.btn-xl.active,
.open .dropdown-toggle.btn-xl {
    background-color: inherit;
    color: inherit;
}
@keyframes btn-xl-animate-color {
    from {
        background-color: $color-primary;
        color: #fff;
    }
    to {
        background-color: #fff;
        color: $color-primary;
    }
}
section {
    padding: 150px auto;
    font-size: 1.1em;
    line-height: 1.5em;
    font-weight: 600;
    font-style: normal;
}
.text-muted {
    font-size: 1.1em;
    line-height: 1.5em;
}
.lightbg {
    color: $color-primary;
}
.lightbg .text-muted {
    color: $color-muted;
}
.darkbg {
    color: $color-primary-dark;
}
.darkbg .text-muted {
    color: $color-muted-dark;
}
#what-we-do {
    background: radial-gradient(circle farthest-side at 15% 15%, #fdc124, #f58958);
    background: -moz-radial-gradient(circle farthest-side at 15% 15%, #fdc124, #f58958);
    background: -webkit-radial-gradient(circle farthest-side at 15% 15%, #fdc124,#f58958);
}
#what-we-do .fa-stack {
    width: 2.5em;
    height: 2.5em;
}
section h2.section-heading {
    font-size: 3.5em;
    font-weight: 700;
    margin-bottom: 1em;
}
section h3.section-subheading {
    max-width: 70%;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.4em;
    line-height: 1.5em;
    font-weight: 600;
    font-style: normal;
}
.icon-digital-transformation {
    background-size: 90%;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center 0;
    background-image: url("#{$site-baseurl}img/icon/DigitalTransformationWhite.svg");
}
.icon-security {
    background-size: 90%;
    background-repeat: no-repeat;
    background-position: center 0;
    background-image: url("#{$site-baseurl}img/icon/DigitalSafetyComplianceWhite.svg");
}
.icon-migration {
    background-size: 90%;
    background-repeat: no-repeat;
    background-position: center 0;
    background-image: url("#{$site-baseurl}img/icon/CloudInfrastructureWhite.svg");
}
.icon-managed {
    background-size: 90%;
    background-repeat: no-repeat;
    background-position: center 0;
    background-image: url("#{$site-baseurl}img/icon/ManagedOperationsWhite.svg");
}
#our-approach .fa-stack {
    width: 6em;
    height: 6em;
}
#our-approach h4 {
    font-size: 1.6em;
    font-weight: 700;
}
.icon-strategy {
    background-size: 90%;
    background-repeat: no-repeat;
    background-position: left center;
    background-image: url('#{$site-baseurl}img/icon/Strategy.svg');
}
.icon-architecture {
    background-size: 90%;
    background-repeat: no-repeat;
    background-position: 25% 70%;
    background-image: url('#{$site-baseurl}img/icon/ArchitecturePlus.svg');
}
.icon-sre {
    background-size: 90%;
    background-repeat: no-repeat;
    background-position: 30% 80%;
    background-image: url('#{$site-baseurl}img/icon/ReliabilityEngineering.svg');
}
section#contact {
    background: url("#{$site-baseurl}img/map-image.png"), radial-gradient(circle farthest-side at 15% 15%, #fdc124, #f58958);
    background: url("#{$site-baseurl}img/map-image.png"), -moz-radial-gradient(circle farthest-side at 15% 15%, #fdc124, #f58958);
    background: url("#{$site-baseurl}img/map-image.png"), -webkit-radial-gradient(circle farthest-side at 15% 15%, #fdc124,#f58958);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center center;
}
section#contact h3.section-subheading {
    color: #fff;
}
section#contact input, section#contact textarea {
    color: #808080;
}
footer {
    color: $color-primary;
}

.right>.list-inline>li {
	padding-right:0px;
}
@media(max-width:767px) {
    /* body {
        background-color: red;
    } */
    .container {
        min-width: 421px;
    }
    header .intro-text {
        padding-top: 10em;
        text-align: center;
    }
    header .intro-text #main-illustration {
        width: 80%;
        margin: auto;
        float: none;
    }
    header .intro-text .intro-lead-in {
        font-size: 18pt;
        text-align: center;
        width: 60%;
        margin: auto;
        margin-top: 4em;
    }
    header .intro-text .intro-heading {
        font-size: 14pt;
        text-align: center;
        width: 80%;
        margin: 1em auto;
    }
    .navbar-brand img {
        margin-top: -0.3em;
    }
    .navbar-shrink img {
        margin-top: -0.3em;
    }
    section {
        padding: 50px 0;
    }
    .btn-xl {
        display: block;
        margin-left: auto;
        margin-right: auto;
        min-width: 10em;
        max-width: 30em;
    }
    #our-approach .fa-stack {
        height: 8em;
        width: 100%;
        max-width: 8em;
    }
    .icon-strategy {
        background-size: 70%;
        background-position: 20% 65%;
    }
    .icon-architecture {
        background-size: 70%;
        background-position: 45% 65%;
    }
    .icon-sre {
        background-size: 70%;
        background-position: 35% 75%;
    }
    #what-we-do .fa-stack {
        height: 3.5em;
    }
    .icon-digital-transformation,
    .icon-security,
    .icon-migration,
    .icon-managed {
        background-position: center 1.2em;
    }
    .left {
        text-align: center;
    }
    .right {
        text-align: center;
    }
    section h2.section-heading {
        font-size: 1.8em;
    }
}
@media(max-width:420px) {
    /*body {
        background-color: purple;
    }*/
    header .intro-text {
        padding-top: 7em;
    }
    header .intro-text .intro-lead-in {
        font-size: 18pt;
        text-align: center;
        width: 90%;
        margin: auto;
        margin-top: 2em;
    }
    .container {
        min-width: 15em;
    }
    #our-approach .fa-stack {
        height: 5em;
        width: 100%;
        max-width: 5em;
    }
    section h3.section-subheading {
        font-size: 1.4em;
    }
}
@media (min-width: 768px) and (max-width:991px) {
    /* body {
        background-color: green;
    } */
    header .intro-text .intro-lead-in {
        font-size: 18pt;
    }
    header .intro-text .intro-heading {
        font-size: 14pt;
    }
    #our-approach .fa-stack {
        height: 8em;
        width: 8em;
    }
    .icon-strategy {
        background-size: 70%;
        background-position: 20% 65%;
    }
    .icon-architecture {
        background-size: 70%;
        background-position: 45% 65%;
    }
    .icon-sre {
        background-size: 70%;
        background-position: 35% 75%;
    }
    .left {
        text-align: center;
    }
    .right {
        text-align: center;
    }
}
@media (min-width:992px) and (max-width: 1199px){
    /* body {
        background-color: blue;
    } */
    header .intro-text .intro-lead-in {
        font-size: 24pt;
    }
    header .intro-text .intro-heading {
        font-size: 14pt;
    }
    .icon-strategy {
        background-size: 65%;
        background-position: 0% 70%;
    }
    .icon-architecture {
        background-size: 70%;
        background-position: 10% 75%;
    }
    .icon-sre {
        background-size: 70%;
        background-position: 10% 75%;
    }
}
@media(min-width:1200px) {
    /* body {
        background-color: yellow;
    } */
    header .intro-text .intro-lead-in {
        font-size: 30pt;
    }
    header .intro-text .intro-heading {
        font-size: 16pt;
        padding-right: 2em;
    }
    .icon-strategy {
        background-size: 75%;
        background-position: 10% 70%;
    }
    .icon-architecture {
        background-size: 75%;
        background-position: 35% 80%;
    }
    .icon-sre {
        background-size: 70%;
        background-position: 35% 82%;
    }
}
